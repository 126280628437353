import React from "react";
import { Blurhash } from "react-blurhash";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Link,
  styled,
} from '@mui/material';
import { NftMetadata } from "../types/types";
import Image from "./Image";

const Root = styled('div')({
});

const NftImg = styled(Image)({

  width: 300,
  height: 300,

})


interface Props {
  tokenId: number;
  metadata: NftMetadata;
}

const NftCard: React.FC<Props> = ({ tokenId, metadata }) => {
  const name = `HayApe #${tokenId}`;
  const openSeaLink = `${process.env.GATSBY_OPENSEA_BASE}/assets/${process.env.GATSBY_CONTRACT_ADDRESS}/${tokenId}`;
  return (
    <Root>
      <Card sx={{ maxWidth: 345 }}>
        <CardHeader
          title={name}
          subheader={metadata.rarity}
        // action={
        //   <IconButton aria-label="share">
        //     <ShareIcon />
        //   </IconButton>
        // }
        />
        
        <NftImg
          src={metadata.image}
          loader={
            // <Skeleton variant="rectangular" width={300} height={300}/>
            <Blurhash hash={metadata.blurHash} width={300} height={300} punch={1} />
          }
        />

        <CardActions disableSpacing>
          <Button
            component={Link}
            target="_blank"
            href={openSeaLink}
            color="inherit"
          >
            View on OpenSea
          </Button>
        </CardActions>
      </Card>
    </Root>
  );
};

export default NftCard;