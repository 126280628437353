import React, { ReactNode, useEffect, useRef, useState } from "react";

import { styled, SxProps } from '@mui/material';


const Root = styled('div')({
  position: 'relative'
})

const RootImg = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

interface Props {
  src: string;
  className?: string;
  loader?: ReactNode;
}

const Image: React.FC<Props> = ({ src, className, loader }) => {
  const imgRef = useRef<HTMLImageElement>();


  const [ loading, setLoading ] = useState(false);

  const onLoad = (e: Event) => {
    setLoading(false);
  }

  useEffect(() => {
    imgRef.current?.addEventListener('load', onLoad)
    setLoading(true);

    return () => {
      imgRef.current?.addEventListener('load', onLoad);
    }
  }, []);

  return (
    <Root className={className}>
      <RootImg className={className} src={src} ref={imgRef as any} style={{opacity: loading ? 0 : 1}}/>
      {loading && Boolean(loader) ? loader : null}
    </Root>
  );
};

export default Image;