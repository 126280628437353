import React from "react"
import { Router } from "@reach/router"

import MyApes from '../user/MyHayApes';
// import GazeboPage from "../user/Gazebo";
import PrivateRoute from "../components/PrivateRoute";



const App = () => (
  <Router>
    <PrivateRoute path="/user/my-hayapes" component={MyApes} />
    {/* <PrivateRoute path="/user/gazebo" component={GazeboPage} /> */}
  </Router>

)

export default App
