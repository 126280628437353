import React, { useEffect } from "react";

import { Container, Grid, Stack, styled, Typography } from '@mui/material';
import Layout from "../components/Layout/Layout";

import { actions } from '../services/nft';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import NftCard from "../components/NftCard";
import MintButton from "../components/MintButton";

const Root = styled('div')({

});

const MyHayApes: React.FC = () => {
  const state = useSelector((state: RootState) => state.nft);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadMyCollection());
  }, []);


  return (
    <Layout title="My HayApe's" loading={state.myCollectionLoading}>
      <Container maxWidth="lg">
        <Typography paragraph variant="h5" align="center">My HayApe's</Typography>

        {
          state.myCollection.length == 0 ? (
            <Stack direction="column" alignItems="center" spacing={4}>
              <Typography>You don't have any HayApe's yet. Mint now while they are available.</Typography>
              <MintButton />
            </Stack>
          ) : null
        }

        <Grid container spacing={2} justifyContent="center">
          {
            state.myCollection.map(tokenId => (
              <Grid item key={tokenId}>
                <NftCard tokenId={tokenId} metadata={state.metadata[ tokenId ]} />
              </Grid>
            ))
          }
        </Grid>
        {/* <Stack direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
          
        </Stack> */}
      </Container>
    </Layout>
  );
};

export default MyHayApes;