import React from "react";
import { RouteComponentProps } from "@reach/router";
import { styled, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { navigate } from "gatsby";

const Root = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface PrivateRouteProps extends RouteComponentProps {
  component: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  const wallet = useSelector((state: RootState) => state.wallet);

  if (wallet.loading) {
    return (
      <Root>
        <Typography>
          HayApe
        </Typography>
      </Root>
    );
  }

  if (!wallet.account) {
    navigate('/', { replace: true });
    return null;
  }

  return <Component {...rest} />
};

export default PrivateRoute;